






















































import LazyHydrate from 'vue-lazy-hydration';

import { SfButton, SfImage, SfIcon, SfLink } from '~/components';

import { ref, defineComponent, onMounted, watch, computed } from '@nuxtjs/composition-api';
import StoresModal from '~/modules/countries/components/StoresModal.vue';
import SfCharacteristic from '~/components/molecules/SfCharacteristic/SfCharacteristic.vue';
import { useConfig, useMagentoConfiguration, useWebsiteCode } from '~/composables';
import { getItem } from '~/helpers/asyncLocalStorage';

export default defineComponent({
  name: 'StoreSwitcher',
  components: {
    StoresModal,
    SfButton,
    SfCharacteristic,
    SfImage,
    LazyHydrate,
    SfIcon,
    SfLink
  },
  props: {
    fromFooter: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { config } = useConfig();

    const {
      selectedCurrency,
      selectedLocale,
    } = useMagentoConfiguration();

    const {
      isMe,
      isAu
    } = useWebsiteCode();

    const isLangModalOpen = ref(false);

    const getShipToCountry = ref();
    const shipToCountryIcon = ref();

    watch(isLangModalOpen, () => {
      isLangModalOpen.value
        ? document.body.classList.add('no-scroll')
        : document.body.classList.remove('no-scroll');
    });

    onMounted(async ()=> {
      getShipToCountry.value = await getItem('selected-country') || '';
      shipToCountryIcon.value = await getItem('selected-country-icon') || '';
    })

    const shipToCountry = computed(() => {
      if (isAu.value) return 'Austria';
      if (isMe.value) return getShipToCountry.value || 'UAE';
      return getShipToCountry.value || 'International';
    });

    return {
      selectedCurrency,
      selectedLocale,
      isLangModalOpen,
      storeConfig: config,
      shipToCountry,
      shipToCountryIcon,
      isAu,
      isMe,
    };
  },
});
