








































































































import {
  defineComponent, onMounted, computed, PropType, ref
} from '@nuxtjs/composition-api';
import {
  SfLink,
  SfButton,
  SfList,
  SfInput,
  SfImage,
  SfIcon,
  SkeletonLoader,
} from '~/components';
import SfBottomModal from '~/components/molecules/SfBottomModal/SfBottomModal.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import { StoreConfig } from '~/modules/GraphQL/types';
import {AvailableStores, useStore, useCountries, useWebsiteCode, useCurrency} from '~/composables';

import { setItem, getItem, removeItem } from '~/helpers/asyncLocalStorage';
import {useCountriesStore} from "~/modules/countries/stores/countries";

export default defineComponent({
  name: 'StoresModal',
  components: {
    SfButton,
    SfLink,
    SfList,
    SfBottomModal,
    SfInput,
    SfIcon,
    SfImage,
    LoadWhenVisible,
    SkeletonLoader,
  },
  props: {
    isLangModalOpen: Boolean,
    storeConfig: {
      type: Object as PropType<StoreConfig>,
      default: (): StoreConfig => ({}),
    }
  },
  emits: ['closeModal'],
  setup(props, context) {
    const {
      stores,
      change: changeStore,
      load: loadStores,
    } = useStore();
    const {
      currency: currencies,
      change: changeCurrency,
    } = useCurrency();
    const { isMe, isInt } = useWebsiteCode();
    const { changeCountry } = useCountries();
    const { countries } = useCountriesStore();
    const shipToCountry = ref();
    const selectedCountryIconUrl= ref();

    const setShipToCountry = async (country) => {
      await changeCountry(country);

      if (isMe.value) {
        if (currencies.value?.available_currency_codes?.includes(country.currency_code)) {
          await changeCurrency({ id: country.currency_code });
          return;
        }
      }

      context.emit('closeModal');
      await removeItem('selected-country');
      await removeItem('selected-country-icon');
      window.location.assign(country.url);
    };

    const countryList = ref(null);
    const filterValue =  ref('');

    const availableStores = computed<AvailableStores>(() => stores.value ?? []);

    onMounted(async () => {
      const countriesData = countries;

      if (!(await getItem('vsf-selected-country'))) {
        if (isMe.value) await setItem("selected-country", 'UAE');
        if (isInt.value) await setItem("selected-country", 'International');
      }

      shipToCountry.value = await getItem('selected-country')  || '';
      selectedCountryIconUrl.value = await getItem('selected-country-icon')  || '';

      countryList.value = countriesData.map(item => {
        if(item.code === shipToCountry.value) {
          item.position = 0;
          item.is_primary = true;
        }
        return item;
      });

      if (stores.value && stores.value?.length) return;
      await loadStores();
    });

    const sortedCountries = computed(() => {
      if (!countryList.value) return [];

      return countryList.value
        .sort((countryCur, countryNext) => countryCur.is_primary && countryNext.is_primary
          ? 0
          : countryCur.is_primary && !countryNext.is_primary
            ? -1
            : 1
        )
        .filter(country => country.name.toLowerCase().includes(filterValue.value.toLowerCase()))
        .sort((countryCur, countryNext) => (
          countryCur.name.toLowerCase().indexOf(filterValue.value.toLowerCase()) < countryNext.name.toLowerCase().indexOf(filterValue.value.toLowerCase())
            ? -1
            : 1
          )
        )
    });

    return {
      availableStores,
      changeStore,
      loadStores,
      sortedCountries,
      shipToCountry,
      selectedCountryIconUrl,
      setShipToCountry,
      filterValue,
      countryList,
      currencies,
    };
  }
});
